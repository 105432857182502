import {
  // DotChartOutlined,
  SafetyCertificateOutlined,
  BellOutlined,
  AlertOutlined,
  AuditOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  AppstoreOutlined,
  UserOutlined,
  TeamOutlined,
  LineChartOutlined,
  MessageOutlined,
} from '@ant-design/icons'
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  // {
  //   key: 'home',
  //   path: `${APP_PREFIX_PATH}/dashboard`,
  //   title: 'sidenav.dashboard',
  //   icon: LineChartOutlined,
  //   breadcrumb: false,
  //   isRestricted: false,
  //   submenu: [],
  // },
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'sidenav.dashboard',
    icon: LineChartOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [
      {
        key: 'security',
        path: `${APP_PREFIX_PATH}/dashboard-security`,
        title: 'sidenav.dashboard.security',
        // icon: LineChartOutlined,
        breadcrumb: false,
        isRestricted: false,
        submenu: [],
      },
      {
        key: 'performance',
        path: `${APP_PREFIX_PATH}/dashboard-performance`,
        title: 'sidenav.dashboard.performance',
        breadcrumb: false,
        isRestricted: true,
        submenu: [],
      },
    ],
  },
  {
    key: 'applications',
    path: `${APP_PREFIX_PATH}/applications`,
    title: 'sidenav.applications',
    icon: AppstoreOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  {
    key: 'applications-new',
    path: `${APP_PREFIX_PATH}/applications-new`,
    title: 'sidenav.applications.new',
    icon: AppstoreOutlined,
    breadcrumb: false,
    isRestricted: true,
    submenu: [],
  },
  {
    key: 'alert',
    path: `${APP_PREFIX_PATH}/alerts`,
    title: 'sidenav.alerts',
    icon: AlertOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  {
    key: 'alert-new',
    path: `${APP_PREFIX_PATH}/alerts-new`,
    title: 'sidenav.alerts.new',
    icon: AlertOutlined,
    breadcrumb: false,
    isRestricted: true,
    submenu: [],
  },
  {
    key: 'messages',
    path: `${APP_PREFIX_PATH}/messages`,
    title: 'sidenav.messages',
    icon: MessageOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  {
    key: 'policies',
    path: `${APP_PREFIX_PATH}/policies`,
    title: 'sidenav.policies',
    icon: SafetyCertificateOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  {
    key: 'policies-new',
    path: `${APP_PREFIX_PATH}/policies-new`,
    title: 'sidenav.policies.new',
    icon: SafetyCertificateOutlined,
    breadcrumb: false,
    isRestricted: true,
    submenu: [],
  },
  {
    key: 'audit',
    path: `${APP_PREFIX_PATH}/audit`,
    title: 'sidenav.audit',
    icon: AuditOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  {
    key: 'audit-new',
    path: `${APP_PREFIX_PATH}/audit-new`,
    title: 'sidenav.audit.new',
    icon: AuditOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [
      {
        key: 'change-logs',
        path: `${APP_PREFIX_PATH}/audit-new/change-logs`,
        title: 'sidenav.audit.new.change.logs',
        breadcrumb: false,
        isRestricted: false,
        submenu: [],
      },
      {
        key: 'malware-logs',
        path: `${APP_PREFIX_PATH}/audit-new/malware-logs`,
        title: 'sidenav.audit.new.malware.logs',
        icon: null,
        breadcrumb: false,
        isRestricted: false,
        submenu: [],
      },
      {
        key: 'performance-logs',
        path: `${APP_PREFIX_PATH}/audit-new/performance-logs`,
        icon: null,
        title: 'sidenav.audit.new.performance.logs',
        breadcrumb: false,
        isRestricted: true,
        submenu: [],
      },
      {
        key: 'rpa-logs',
        path: `${APP_PREFIX_PATH}/audit-new/rpa-logs`,
        icon: null,
        title: 'sidenav.audit.new.rpa.logs',
        breadcrumb: false,
        isRestricted: true,
        submenu: [],
      },
      {
        key: 'navigation-history',
        path: `${APP_PREFIX_PATH}/audit-new/navigation-history`,
        icon: null,
        title: 'sidenav.audit.new.navigation.history',
        breadcrumb: false,
        isRestricted: true,
        submenu: [],
      },
    ],
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'sidenav.settings',
    icon: SettingOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  // {
  //   key: 'settings-new',
  //   path: `${APP_PREFIX_PATH}/settings-new`,
  //   title: 'sidenav.new.settings',
  //   icon: SettingOutlined,
  //   breadcrumb: false,
  //   isRestricted: true,
  //   submenu: [
  //     {
  //       key: 'organization-details',
  //       path: `${APP_PREFIX_PATH}/settings-organization-details`,
  //       title: 'sidenav.organization.details',
  //       icon: null,
  //       breadcrumb: false,
  //       isRestricted: false,
  //       submenu: [],
  //     },
  //     // {
  //     //   key: 'billing-information',
  //     //   path: `${APP_PREFIX_PATH}/settings-billing-informations`,
  //     //   title: 'sidenav.billing.information',
  //     //   icon: null,
  //     //   breadcrumb: false,
  //     //   isRestricted: false,
  //     //   submenu: [],
  //     // },
  //     {
  //       key: 'integrations',
  //       path: `${APP_PREFIX_PATH}/settings-integrations`,
  //       title: 'sidenav.integrations',
  //       icon: null,
  //       breadcrumb: false,
  //       isRestricted: false,
  //       submenu: [],
  //     },
  //     {
  //       key: 'global-thresholds',
  //       path: `${APP_PREFIX_PATH}/settings-global-thresholds`,
  //       title: 'sidenav.global.thresholds',
  //       key: 'learning-mode',
  //       path: `${APP_PREFIX_PATH}/settings-learning-mode`,
  //       title: 'sidenav.learning.mode',
  //       icon: null,
  //       breadcrumb: false,
  //       isRestricted: false,
  //       submenu: [],
  //     },
  //     // {
  //     //   key: 'learning-mode',
  //     //   path: `${APP_PREFIX_PATH}/settings-learning-mode`,
  //     //   title: 'sidenav.learning.mode',
  //     //   icon: null,
  //     //   breadcrumb: false,
  //     //   isRestricted: false,
  //     //   submenu: [],
  //     // },
  //   ],
  // },
  {
    key: 'help',
    path: `${APP_PREFIX_PATH}/help`,
    title: 'sidenav.help',
    icon: QuestionCircleOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  {
    key: 'notifications',
    path: `${APP_PREFIX_PATH}/notifications`,
    title: 'sidenav.notifications',
    icon: BellOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  {
    key: 'groups',
    path: `${APP_PREFIX_PATH}/groups`,
    title: 'sidenav.groups',
    icon: TeamOutlined,
    breadcrumb: false,
    isRestricted: false,
    submenu: [],
  },
  {
    key: 'users-and-groups',
    path: `${APP_PREFIX_PATH}/users-and-groups`,
    title: 'sidenav.users.and.groups',
    icon: TeamOutlined,
    breadcrumb: false,
    isRestricted: true,
    submenu: [],
  },
  {
    key: 'owner-settings',
    path: `${APP_PREFIX_PATH}/owner-settings`,
    title: 'sidenav.owner.settings',
    icon: UserOutlined,
    breadcrumb: false,
    isRestricted: true,
    submenu: [],
  },
  // {
  //   key: 'utility',
  //   path: `${APP_PREFIX_PATH}/utility`,
  //   title: 'sidenav.utility',
  //   icon: ToolOutlined,
  //   breadcrumb: false,
  //   isRestricted: false,
  //   submenu: [],
  // },
]

const navigationConfig = [...dashBoardNavTree]

export default navigationConfig
