import * as Client from 'redux/constants/Client'

const userData = localStorage.getItem(Client.SURF_CLIENT_DATA) ?? ''
const userInfo = localStorage.getItem(Client.SURF_CLIENT_INFO) ?? ''
const initState = {
  tmpLogin: { email: '', password: '' },
  login: {
    error: false,
    loading: false,
    successLoading: false,
    fetch: false,
    info: userInfo
      ? JSON.parse(userInfo)
      : {
          loginType: Client.CUSTOMLOGIN,
        },
  },
  user: userData ? JSON.parse(userData) : { session: false, email: '', accessToken: '' },
}

export const clientInfo = (state = initState, { type, payload }) => {
  switch (type) {
    case Client.LOGIN_START:
      return {
        ...state,
        login: { ...state.login, error: false, loading: false, successLoading: false },
      }

    case Client.LOGIN_ERROR:
      return {
        ...state,
        login: { ...state.login, error: payload, loading: false, successLoading: false },
      }

    case Client.LOGIN_SUCCESS_LOADING:
      return {
        ...state,
        login: {
          ...state.login,
          error: false,
          loading: false,
          successLoading: payload,
        },
      }

    case Client.LOGIN_LOADING:
      return {
        ...state,
        login: {
          ...state.login,
          loading: payload || false,
        },
      }

    case Client.LOGIN_INFO_UPDATE:
      localStorage.setItem(Client.SURF_CLIENT_INFO, JSON.stringify({ ...payload }))
      return { ...state, login: { ...state.login, info: payload } }

    case Client.REDUX_RESET:
      localStorage.removeItem(Client.SURF_CLIENT_DATA)
      localStorage.removeItem(Client.SURF_CLIENT_INFO)
      return initState

    case Client.USER_UPDATE:
      localStorage.setItem(Client.SURF_CLIENT_DATA, JSON.stringify({ ...payload }))
      return { ...state, user: { ...payload } }

    case Client.USER_SESSION_DISABLED:
      return { ...state, user: { ...payload, session: false } }

    default:
      return state
  }
}
