import React, { useState } from 'react'
import { Menu, Dropdown, Avatar, Modal, Form, Spin, message, Button, Row, Input } from 'antd'
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import Icon from 'components/util-components/Icon'
import { signOut } from 'redux/actions/Auth'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import { changePasswordUser } from 'services/users'
import { RolesEnum } from 'utils/enum'

const menuItem = [
  {
    title: 'Account Setting',
    icon: SettingOutlined,
    path: '/',
  },
  {
    title: 'Billing',
    icon: ShopOutlined,
    path: '/',
  },
  {
    title: 'Help Center',
    icon: QuestionCircleOutlined,
    path: '/',
  },
]

export const NavProfile = ({ name, role, signOut }) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const [resetModal, setResetModal] = useState(false)
  const toggleResetModal = () => setResetModal(!resetModal)

  const { mutate: changePasswordMutation, isLoading: updateLoading } = useMutation(
    changePasswordUser,
    {
      onSuccess: (updateData) => {
        message.destroy()
        if (updateData.success) {
          toggleResetModal()
          form.resetFields()
          message.success(updateData.message)
        }
      },
    },
  )

  const profileImg = '/img/avatars/avatar.png'
  const profileMenu = (
    <div className="nav-profile nav-dropdown" data-image="session.avatar">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{name}</h4>
            <span className="text-muted">{RolesEnum[role]}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={menuItem.length + 1} onClick={(e) => toggleResetModal()}>
            <span>
              <EditOutlined className="mr-3" />
              <span className="font-weight-normal">
                {intl.formatMessage({ id: 'setting.change.password' })}
              </span>
            </span>
          </Menu.Item>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i} disabled>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            )
          })}

          <Menu.Item key={menuItem.length + 2} onClick={(e) => signOut()}>
            <span data-button-id="sign.out">
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">
                {intl.formatMessage({ id: 'setting.signout' })}
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
      <Modal
        title={intl.formatMessage({ id: 'setting.change.password' })}
        visible={resetModal}
        destroyOnClose
        footer={false}
        onCancel={toggleResetModal}
      >
        <Spin spinning={updateLoading}>
          <Form
            form={form}
            onFinish={(values) =>
              changePasswordMutation({
                ...values,
              })
            }
            layout="vertical"
          >
            <Form.Item
              label={intl.formatMessage({ id: 'table.columns.old.password' })}
              name="oldPassword"
              rules={[{ required: true, min: 2 }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({ id: 'table.columns.new.password' })}
              name="newPassword"
              rules={[{ required: true, min: 2 }]}
            >
              <Input.Password />
            </Form.Item>
            <Row justify="end" align="middle">
              <Button onClick={toggleResetModal} data-button-id="nav.profile.cancel">
                {intl.formatMessage({ id: 'general.cancel' })}
              </Button>
              <Button
                htmlType="submit"
                className="ml-2"
                type="primary"
                data-button-id="nav.profil.submit"
              >
                {intl.formatMessage({ id: 'general.submit' })}
              </Button>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} data-image="session.avatar" />
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

const mapStateToProps = ({ auth }) => {
  const { name, role } = auth
  return { name, role }
}

export default connect(mapStateToProps, { signOut })(NavProfile)
