import React from 'react'
import { APP_NAME } from 'configs/AppConfig'
import { useIntl } from 'react-intl'

export default function Footer() {
  const intl = useIntl()
  const token = localStorage.getItem('AUTH_TOKEN')
  return (
    <footer className="footer">
      <span>
        {intl.formatMessage({ id: 'general.copyright' })} &copy; {`${new Date().getFullYear()}`}{' '}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span>{' '}
        {intl.formatMessage({ id: 'general.all.right.reserved' })}
      </span>
      {process.env.NODE_ENV === 'development' && (
        <button className="font-weight-bold" onClick={() => navigator.clipboard.writeText(token)}>
          Copy Token
        </button>
      )}
    </footer>
  )
}
