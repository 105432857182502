import store from 'redux/store'
import { RolesEnum } from './enum'

export const roleReadOnly = () => {
  const role = store.getState().auth.role
  const isRead = role === RolesEnum.Contributor
  return isRead
}
export const isTrialUser = () => store.getState().auth.isTrialUser
export const ProductOwnerOnly = () => store.getState().auth.role === RolesEnum.ProductOwner
export const TrialUserView = () => store.getState().auth.isTrialUser
