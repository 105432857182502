import axiosInstance from 'auth/FetchInterceptor'
import { env } from 'configs/EnvironmentConfig'
import { PUBLIC_REQUEST_KEY } from 'constants/ApiConstant'

export const adminFetchLoginConfig = (email) => {
  return axiosInstance({
    url: `auth/fetch-login-configurations?token=${env.THIRD_PARTY_TOKEN}`,
    headers: {
      [PUBLIC_REQUEST_KEY]: 'true',
    },
    method: 'post',
    data: { email },
  })
}

export const idpAuth = (code) => {
  return axiosInstance({
    url: `auth/idp-login?token=${env.THIRD_PARTY_TOKEN}`,
    headers: {
      [PUBLIC_REQUEST_KEY]: 'true',
    },
    method: 'post',
    data: { code },
  })
}

export const isLoggedIn = () => {
  return axiosInstance({
    url: `auth/logged-in?token=${env.THIRD_PARTY_TOKEN}`,
    method: 'post',
  }).catch((error) => {
    console.log(error);
  })
}

