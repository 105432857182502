export const REDUX_RESET = 'REDUX_RESET'

export const TMP_LOGIN_UPDATE = 'TMP_LOGIN_UPDATE'
export const TMP_LOGIN_RESET = 'TMP_LOGIN_RESET'

export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS_LOADING = 'LOGIN_SUCCESS_LOADING'
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_INFO_UPDATE = 'LOGIN_INFO_UPDATE'

export const USER_UPDATE = 'USER_UPDATE'
export const USER_SESSION_DISABLED = 'USER_SESSION_DISABLED'

export const OPTIONS_UPDATE = 'OPTIONS_UPDATE'

export const POLICIES_UPDATE = 'POLICIES_UPDATE'
export const POLICIES_RESET = 'POLICIES_RESET'

/**
 * Login Types name constants
 */
export const GOOGLE_WORKSPACE = 'GoogleWorkSpace'
export const GOOGLE_MAIL = 'Google'
export const MICROSOFT_LOGIN = 'Microsoft'
export const AZURE = 'AzureAD'
export const FORGEROCK = 'ForgeRock'
export const OKTA = 'Okta'
export const OKTAWIDGET = 'OktaWidget'
export const SAML2 = 'SAML2'
export const CUSTOMLOGIN = 'CustomLogin'

export const SURF_CLIENT_DATA = 'SURF_CLIENT_DATA'
export const SURF_CLIENT_INFO = 'SURF_CLIENT_INFO'
