import enLang from './entries/en_US'
import zhLang from './entries/zh_CN'
import frLang from './entries/fr_FR'
import jaLang from './entries/ja_JP'
import koLang from './entries/ko_KR'

const AppLocale = {
  en: enLang,
  zh: zhLang,
  fr: frLang,
  ja: jaLang,
  ko: koLang,
}

export default AppLocale
