import fetch from 'auth/FetchInterceptor'
import { formatFiltersForRest } from 'utils/filter'

export const getCompanies = () => {
  return fetch({
    url: `company`,
    method: 'get',
  })
    .then((data) => data.data)
    .catch((err) => console.log(err))
}

export const listOfCompanies = (filters, active) => {
  let formattedFilters = formatFiltersForRest(filters)
  return fetch({
    url: `company?sort=updatedAt,DESC&filter=deletedAt||${active}${formattedFilters}`,
    method: 'get',
  })
    .then((data) => data.data)
    .catch((err) => console.log(err))
}

export const getSingleCompany = (id) => {
  if (!id) {
    return
  }
  return fetch({
    url: `company/${id}`,
    method: 'get',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const updateOwnerCompany = (id) => {
  if (!id) return
  return fetch({
    url: `auth/set-active-company/${id}`,
    method: 'post',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const getCompanyFeatures = (id) => {
  return fetch({
    url: `company-features?limit=1&join=company&filter=company.id||eq||${id}`,
    method: 'get',
  })
    .then((data) => data.data?.[0])
    .catch((err) => console.log(err))
}
