import antdkoKR from 'antd/es/locale/ko_KR'
import koMsg from '../locales/ko_KR.json'

const KRLang = {
  antd: antdkoKR,
  locale: 'ko',
  messages: {
    ...koMsg,
  },
}
export default KRLang
