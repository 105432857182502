import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          component={lazy(() => import(`./authentication/login`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/idp-login`}
          component={lazy(() => import(`./authentication/idp-login`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/onboarding-login`}
          component={lazy(() => import(`./authentication/onBoarding`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/signup`}
          component={lazy(() => import(`./authentication/register`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/signup-code`}
          component={lazy(() => import(`./authentication/register-code`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/company-info`}
          component={lazy(() => import(`./authentication/company-information`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/usage-planning`}
          component={lazy(() => import(`./authentication/usage-planning`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/team-workspace`}
          component={lazy(() => import(`./authentication/team-workspace`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/invite-teammate`}
          component={lazy(() => import(`./authentication/invite-teammate`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/existing-company`}
          component={lazy(() => import(`./authentication/existing-company`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/mfa-verification`}
          component={lazy(() => import(`./authentication/mfa`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/clients/reset-password`}
          component={lazy(() => import(`./authentication/client-password-reset`))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/client/login`}
          component={lazy(() => import(`./authentication/client-login`))}
        />

        <Route path={`/utility`} component={lazy(() => import(`../app-views/utility`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews
