import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Layout, Select, Row, message, Tooltip, Tag, Button } from 'antd'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  // SearchOutlined,
  QuestionCircleTwoTone,
  CheckOutlined,
} from '@ant-design/icons'
import Logo from './Logo'
// import NavPanel from './NavPanel'
import NavSearch from './NavSearch'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme'
import { setHelp } from 'redux/actions/Auth'
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant'
import utils from 'utils'
import NavNotification from './NavNotification'
// import NavLanguage from './NavLanguage'
import NavProfile from './NavProfile'
// import SearchInput from './NavSearch/SearchInput.js'
import { useMutation, useQuery } from 'react-query'
import { getCompanies, updateOwnerCompany } from 'services/company'
import { CompanyTypeEnum, RolesEnum } from 'utils/enum'
import { AUTH_TOKEN, GROUP_ID, OLD_GROUP_ID } from 'redux/constants/Auth'
import moment from 'moment'
import './styles.scss'
import { useIntl } from 'react-intl'

const { Option } = Select

const { Header } = Layout
function delay(time) {
  return new Promise(function (resolve) {
    setTimeout(resolve, time)
  })
}
export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    companyId,
    role,
    setHelp,
    help,
    company,
    // direction,
  } = props
  const [searchActive, setSearchActive] = useState(false)
  const [isRefreshed, setIsRefreshed] = useState('null')
  const [load, setLoad] = useState(false)
  const location = window.location.href
  const intl = useIntl()
  const loadRef = useRef(load)
  loadRef.current = load
  const isRefreshedRef = useRef(isRefreshed)
  isRefreshedRef.current = isRefreshed
  const {
    isLoading,
    isFetching,
    data: companiesList,
  } = useQuery('company-list', () => getCompanies(), {
    refetchOnWindowFocus: false,
    notifyOnStatusChange: true,
    enabled: role === RolesEnum.ProductOwner,
  })

  const { mutate: updateMutation, isLoading: updateLoading } = useMutation(updateOwnerCompany, {
    onSuccess: (data) => {
      message.destroy()
      if (data?.success === true) {
        localStorage.setItem(AUTH_TOKEN, data.payload.accessToken)
        localStorage.removeItem(GROUP_ID)
        localStorage.removeItem(OLD_GROUP_ID)
        window.location.reload()
      }
    },
  })

  // const onSearchActive = () => {
  //   setSearchActive(true)
  // }

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }
  useEffect(() => {
    if (!isMobile) {
      onSearchClose()
    }
  })
  const refreshResultHandle = (result) => {
    if (loadRef.current && result.status && result.type === 'policyRefreshResponse') {
      setIsRefreshed('success')
      setTimeout(() => {
        setIsRefreshed('null')
      }, 2500)
      setLoad(false)
    }
  }
  useEffect(() => {
    if (loadRef.current) {
      window.addEventListener('message', (e) => {
        if (location.includes(e.origin)) {
          refreshResultHandle(e?.data)
          return
        }
      })
    }
    // eslint-disable-next-line
  }, [load])
  const numOfDays = () => {
    if (!company || company.type === CompanyTypeEnum.Regular || !company.trialExpiration)
      return null
    const expireDate = moment(company.trialExpiration)
    const today = moment().utcOffset(0).startOf('day')
    const diff = expireDate.diff(today, 'days')
    return (
      <Tag color="orange">
        {diff} {diff === 1 ? 'day' : 'days'} left
      </Tag>
    )
  }
  const isRealCustomer = (id) => companiesList?.find((c) => c.id === id)?.realCustomer ?? false
  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle()
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            <Row justify="center" align="middle" style={{ overflow: 'hidden' }}>
              {numOfDays()}
              <Tooltip
                title={help ? 'Hide Help' : 'Show Help'}
                color="white"
                arrowPointAtCenter
                placement="right"
                overlayInnerStyle={{ border: '#4f738b solid 2px', color: '#365467' }}
              >
                <QuestionCircleTwoTone
                  style={{ fontSize: 22 }}
                  className="mr-2 hoverable"
                  twoToneColor={help ? '' : '#AAAFB4'}
                  onClick={() => setHelp(!help)}
                />
              </Tooltip>
              <Row align="middle">
                <Button
                  className="mr-2"
                  icon={
                    isRefreshed === 'success' ? (
                      <CheckOutlined style={{ color: '#21B573', fontWeight: 'bold' }} />
                    ) : isRefreshed === 'failed' ? (
                      <img
                        style={{ marginBottom: '2px', marginRight: '6px' }}
                        src="/img/ReportProblem.svg"
                        alt=""
                      />
                    ) : (
                      <img
                        style={{ marginBottom: '2px', color: 'red' }}
                        className={load ? 'loader  mr-2' : 'mr-2'}
                        src={load ? '/img/grayedSyncIcon.svg' : '/img/syncPolicyIcon.svg'}
                        alt=""
                      />
                    )
                  }
                  style={{
                    marginInlineStart: 'auto',
                    background: load
                      ? '#F7F7F8'
                      : isRefreshed === 'success'
                      ? 'rgba(33, 181, 115, 0.1)'
                      : isRefreshed === 'failed'
                      ? '#fff2f0'
                      : '',
                  }}
                  type="text"
                  onClick={async (e) => {
                    setLoad(true)
                    window.postMessage({ type: 'refreshPolicy' }, '*')
                    await delay(8000)
                    if (loadRef.current && isRefreshedRef.current === 'null') {
                      setIsRefreshed('failed')
                      setTimeout(() => {
                        setIsRefreshed('null')
                      }, 1500)
                      setLoad(false)
                    }
                  }}
                >
                  <span
                    className={!load ? 'fetech__status' : ''}
                    style={{
                      color: load
                        ? '#72849A' // grayed-out
                        : isRefreshed === 'success'
                        ? '#21B573'
                        : isRefreshed === 'failed'
                        ? '#FF525A'
                        : '#3E79F7',
                    }}
                  >
                    {isRefreshed === 'success'
                      ? intl.formatMessage({ id: 'general.policies.refreshed' })
                      : isRefreshed === 'failed'
                      ? intl.formatMessage({ id: 'general.policies.refreshed.failes' })
                      : intl.formatMessage({ id: 'general.policies.refresh' })}
                  </span>
                </Button>
              </Row>
              {role === RolesEnum.ProductOwner && companyId && (
                <Select
                  disabled={isFetching || isLoading || updateLoading}
                  defaultValue={companyId}
                  style={{
                    width: 170,
                    color: isRealCustomer(companyId) ? 'red' : '',
                  }}
                  onChange={(e) => updateMutation(e)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                >
                  {companiesList?.length > 0 &&
                    companiesList.map((company) => (
                      <Option
                        style={{
                          color: isRealCustomer(company.id) ? 'red' : '',
                        }}
                        label={company.name}
                        key={company.id}
                        value={company.id}
                      >
                        {company.name}
                      </Option>
                    ))}
                </Select>
              )}
              <NavNotification />
              {/* <NavLanguage /> */}
              <NavProfile />
              {/* <NavPanel direction={direction} /> */}
            </Row>
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { companyId, role, help, company } = auth
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    companyId,
    role,
    help,
    company,
  }
}

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle, setHelp })(
  HeaderNav,
)
