import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import SideNav from 'components/layout-components/SideNav'
import TopNav from 'components/layout-components/TopNav'
import Loading from 'components/shared-components/Loading'
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav'
import PageHeader from 'components/layout-components/PageHeader'
import Footer from 'components/layout-components/Footer'
import AppViews from 'views/app-views'
import { Layout, Grid } from 'antd'
import {
  getUserDetails,
  setNotificationsCount,
  refreshNotificationsFunc,
  setAlertsCount,
  refreshAlertsFunc,
} from 'redux/actions/Auth'
import navigationConfig from 'configs/NavigationConfig'
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from 'constants/ThemeConstant'
import utils from 'utils'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { useQuery } from 'react-query'
import { TrialUserView } from 'utils/roleRestrictions'
import { getNewAlertsCount } from 'services/alerts'
import { isLoggedIn } from 'services/admins'
import { env } from 'configs/EnvironmentConfig'

const { Content } = Layout
const { useBreakpoint } = Grid

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  direction,
  getUserDetails,
  setAlertsCount,
  refreshAlertsFunc,
  companyDomain
}) => {
  const { refetch: refetchAlerts } = useQuery(
    'new-access-violations-alerts',
    () => getNewAlertsCount(),
    {
      refetchOnWindowFocus: false,
      notifyOnStatusChange: true,
      enabled: !TrialUserView(),
      onSuccess: (count) => {
        setAlertsCount(count)
      },
    },
  )
  useEffect(() => {
    getUserDetails()
    refreshAlertsFunc(refetchAlerts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setTimeout(() => {
      window.postMessage({ type: 'ADMIN_MODE', state: true }, '*')
    }, 2000)
    window.addEventListener('beforeunload', () => {
      window.postMessage({ type: 'ADMIN_MODE', state: false }, '*')
    })
    return () => {
      window.postMessage({ type: 'ADMIN_MODE', state: false }, '*')
    }
  }, [])

  // Check if Office Next admin is logged in
  useEffect(() => {
    const checkLoggedInStatus = async () => {
      if (!env.IS_JIRAN && !companyDomain?.includes("dailytest")) return
      try {
        await isLoggedIn()
      } catch (error) {
        console.error('Error while checking logged in status:', error)
      }
    }
    const intervalId = setInterval(checkLoggedInStatus, 10e3)
    return () => clearInterval(intervalId)
  }, [companyDomain])

  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint())
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher()

  if (status === 'loading') {
    return <Loading cover="page" />
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }
  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { navCollapsed, navType, locale } = theme;
  const { domain } = auth?.company ?? {}
  return { navCollapsed, navType, locale, companyDomain: domain }
}

const mapDispatchToProps = {
  getUserDetails,
  setNotificationsCount,
  setAlertsCount,
  refreshNotificationsFunc,
  refreshAlertsFunc,
}
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppLayout))
