import fetch from 'auth/FetchInterceptor'
import { THIRD_PARTY_TOKEN } from 'configs/AppConfig'

const JwtAuthService = {}

JwtAuthService.login = async (data) =>
  await fetch({
    url: `auth/sign-in?token=${THIRD_PARTY_TOKEN}`,
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data,
  })
    .then((user) => user)
    .catch((err) => err)

JwtAuthService.getDetails = async (userId) =>
  await fetch({
    url: `users/${userId}?join=company`,
    method: 'get',
  })
    .then((user) => user)
    .catch((err) => err)

export default JwtAuthService
