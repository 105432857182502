import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorkerRegistration'
import './index.scss'
import { SC } from 'constants/ApiConstant'
import SimpleCrypto from 'simple-crypto-js'
// import LogRocket from 'logrocket'
// if (process.env.NODE_ENV === 'development') {
//   LogRocket.init('viudhv/surf-container')
// }
const RootApp = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!process.env.REACT_APP_API_ENDPOINT_URL) {
      fetch(`${process.env.PUBLIC_URL}/cf.json`)
        .then((r) => r.json())
        .then((cf) => {
          const simpleCryto = new SimpleCrypto(SC)
          sessionStorage.setItem('cf', simpleCryto.encrypt(cf))
          setIsReady(true)
        })
    } else setIsReady(true)
  }, [])

  if (isReady) {
    const App = require('./App').default
    return <App />
  }
  return <></>
}
ReactDOM.render(<RootApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
