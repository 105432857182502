import { combineReducers } from 'redux'
import Auth from './Auth'
import Theme from './Theme'
import LocalMfa from './LocalMfa'
import General from './General'
import { clientInfo } from './ClientAuth'

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  localMfa: LocalMfa,
  clientInfo: clientInfo,
  general: General
})

export default reducers
