import { SET_MFA_DATA } from '../constants/LocalMfa'
import { SIGNOUT_SUCCESS } from '../constants/Auth'
const initState = {
  isLocalMfa: false,
  email: '',
  isVerified: false,
}

const localMfa = (state = initState, action) => {
  switch (action.type) {
    case SET_MFA_DATA:
      return {
        ...state,
        isLocalMfa: action.mfa.isLocalMfa,
        email: action.user.email,
        isVerified: action.mfa.isVerified,
      }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        isLocalMfa: false,
        isVerified: false,
        email: '',
      }
    }
    default:
      return state
  }
}

export default localMfa
