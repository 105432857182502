import React, { useEffect, useState } from 'react'
import { Menu, Dropdown, Badge, Avatar, List, Button, message } from 'antd'
import { MailOutlined, BellOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons'
import Flex from 'components/shared-components/Flex'
import { useMutation, useQuery } from 'react-query'
import {
  getNewWorkspaceAlerts,
  updateBulkWorkspaceAlert,
  updateOneWorkspaceAlert,
} from 'services/workspace-alert'
import { AdminAlertTypeEnum, AlertsStatusEnum } from 'utils/enum'
import { useHistory } from 'react-router-dom'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { connect } from 'react-redux'
import { refreshAlertsFunc } from 'redux/actions/Auth'
import { TrialUserView } from 'utils/roleRestrictions'
const getIcon = (icon) => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />
    case 'alert':
      return <WarningOutlined />
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <WarningOutlined />
  }
}

const alertMessages = {
  [AdminAlertTypeEnum.NewRegistrationAttempt]: 'New registration attempt',
}

const getNotificationBody = (list, updateFunc) => {
  return list && list.data?.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list.data}
      renderItem={(item) => (
        <List.Item key={item.id} className="list-clickable" onClick={() => updateFunc(item.id)}>
          <Flex alignItems="center">
            <div className="pr-3">
              {item.img ? (
                <Avatar src={`/img/avatars/${item.img}`} />
              ) : (
                <Avatar className={`ant-avatar-danger`} icon={getIcon(item.icon)} />
              )}
            </div>
            <div className="mr-3">
              <span className="text-gray-light">{alertMessages[item.type]} </span>
              <span className="font-weight-bold text-dark">{item.email} </span>
            </div>
            <small className="ml-auto">{item.time}</small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  )
}

export const NavNotification = (props) => {
  const { refreshAlertsFunc } = props
  const [visible, setVisible] = useState(false)
  let history = useHistory()

  const { refetch, data } = useQuery('workspace-alerts-count', () => getNewWorkspaceAlerts(), {
    refetchOnWindowFocus: false,
    notifyOnStatusChange: true,
    enabled: !TrialUserView(),
  })

  const { mutate: updateMutation } = useMutation(updateBulkWorkspaceAlert, {
    onSuccess: (updateData) => {
      message.destroy()
      if (updateData?.success) {
        refetch()
      }
    },
  })

  const { mutate: singleUpdateMutation } = useMutation(updateOneWorkspaceAlert, {
    onSuccess: (updateData) => {
      message.destroy()
      if (updateData.id) {
        refetch()
      }
    },
  })

  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }

  const updateFunc = (id) => {
    singleUpdateMutation({ id, status: AlertsStatusEnum.Resolved })
    history.push(`${APP_PREFIX_PATH}/audit#workspace-alerts`)
    handleVisibleChange(false)
  }

  useEffect(() => {
    // setRefresh In redux state
    refreshAlertsFunc(refetch)
    // pull new notifications count every 5 min
    const pullAlerts = setInterval(() => {
      if (!TrialUserView()) refetch()
    }, 300000)

    return () => clearInterval(pullAlerts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button
          className="text-primary"
          type="link"
          disabled={!data?.data?.length > 0}
          onClick={() => updateMutation(data?.data?.map((c) => c.id))}
          size="small"
          data-id-button="clear"
        >
          Clear
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data, updateFunc)}</div>
    </div>
  )

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={data?.total}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}
const mapDispatchToProps = {
  refreshAlertsFunc,
}
export default connect(undefined, mapDispatchToProps)(NavNotification)
