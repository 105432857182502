import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import 'views/app-views/table-style.scss'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboard`}
          component={lazy(() => import(`./dashboard-new`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard-performance`}
          component={lazy(() => import(`./dashboard-cio-new`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard-security`}
          component={lazy(() => import(`./dashboard-new`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard-alerts-report`}
          component={lazy(() => import(`./dashboard-new/cards-repots/alerts-report`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard-assets-report`}
          component={lazy(() => import(`./dashboard-new/cards-repots/assets-report`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/dashboard-users-report`}
          component={lazy(() => import(`./dashboard-new/cards-repots/users-report`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/security-settings`}
          component={lazy(() => import(`./policies-new`))}
        />
        <Route path={`${APP_PREFIX_PATH}/alerts-old`} component={lazy(() => import('./alerts'))} />
        <Route
          path={`${APP_PREFIX_PATH}/alerts`} // this replace old alert
          component={lazy(() => import('./alerts-new'))}
        />
        <Route path={`${APP_PREFIX_PATH}/messages`} component={lazy(() => import('./messages'))} />
        <Route
          path={`${APP_PREFIX_PATH}/policies`}
          component={lazy(() => import('./policies-new'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/policies-editor`}
          component={lazy(() => import('./policies-advanced-new/policies-editor'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/policies-list`}
          component={lazy(() => import('./policies-advanced-new/policies-list'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/advanced-policies`}
          component={lazy(() => import('./policies-advanced-new/advanced-policies'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users-and-groups`}
          component={lazy(() => import('./users-and-groups'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/groups-advanced`}
          component={lazy(() => import('./advanced-group'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/workdrive-management`}
          component={lazy(() => import('./workdrive-management'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/zoho-workdrive-management`}
          component={lazy(() => import('./workdrive-management'))}
        />
        <Route path={`${APP_PREFIX_PATH}/audit`} component={lazy(() => import(`./audit`))} />
        <Route
          path={`${APP_PREFIX_PATH}/groups/:groupId`}
          component={lazy(() => import(`./group-details`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users-groups/:groupId`}
          component={lazy(() => import(`./users-and-groups/groups/assign-users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/statistics/client-details/:clientId/:containerId`}
          component={lazy(() => import(`./audit/statistics/clientDetails`))}
        />
        <Route path={`${APP_PREFIX_PATH}/help`} component={lazy(() => import('./help'))} />
        <Route path={`${APP_PREFIX_PATH}/groups`} component={lazy(() => import(`./groups`))} />
        <Route
          path={`${APP_PREFIX_PATH}/notifications`}
          component={lazy(() => import(`./notifications`))}
        />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route
          path={`${APP_PREFIX_PATH}/settings-organization-details`}
          component={lazy(() => import(`./settings-new/organization-details`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-billing-informations`}
          component={lazy(() => import(`./settings-new/billing-information`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-integrations`}
          component={lazy(() => import(`./settings-new/integrations`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-global-thresholds`}
          component={lazy(() => import(`./settings-new/global-thresholds`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-browser-setup`}
          component={lazy(() => import(`./settings-new/browser-setup`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/malware-protection`}
          // add component and change path
          component={lazy(() => import(`./settings-new/malware-protection`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/addition-setup`}
          // add component and change path
          component={lazy(() => import(`./settings-new/additional-setup`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/malicious-processes`}
          // add component and change path
          component={lazy(() => import(`./settings-new/malicious-processes`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings-learning-mode`}
          component={lazy(() => import(`./settings-new/learning-mode`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/owner-settings`}
          component={lazy(() => import(`./owner-settings`))}
        />
        {/* <Route path={`${APP_PREFIX_PATH}/utility`} component={lazy(() => import(`./utility`))} /> */}

        <Route
          path={`${APP_PREFIX_PATH}/applications`} // this replace old application
          component={lazy(() => import(`./applications-new`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/logs/:logId`}
          component={lazy(() => import(`./audit/logs-details`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/malware-logs-by-users/:logId`}
          component={lazy(() => import('./audit-new/malware-logs/malware-by-users/logs-details'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/performance-logs/cio-dasboard/:clientId`}
          component={lazy(() => import('./audit-new/performance-logs/logs-by-users/cio-dashboard'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/navigation-history/:clientId`}
          component={lazy(() => import('./audit-new/navigation-history/domains'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/uploads-recordings/:clientId`}
          component={lazy(() => import('./audit-new/navigation-history/domains'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/paste-recordings/:clientId`}
          component={lazy(() => import('./audit-new/navigation-history/domains'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/user-uploads/:clientId/:domain`}
          component={lazy(() => import('./audit-new/navigation-history/user-session'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/user-pastes/:clientId/:domain`}
          component={lazy(() => import('./audit-new/navigation-history/user-session'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/user-session/:clientId/:domain`}
          component={lazy(() => import('./audit-new/navigation-history/user-session'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/access-graph/:domain`}
          component={lazy(() => import('./audit-new/navigation-history/access-graph'))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/change-logs`}
          component={lazy(() => import(`./audit-new/change-logs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/ai-tools/user-history/:clientId`}
          component={lazy(() => import(`./audit-new/ai-tools/by-users/UsersHistory`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/ai-tools/usage-details/:clientId/:domain`}
          component={lazy(() => import(`./audit-new/ai-tools/by-users/usageDetails`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/malware-logs`}
          component={lazy(() => import(`./audit-new/malware-logs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/performance-logs`}
          component={lazy(() => import(`./audit-new/performance-logs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/rpa-logs`}
          component={lazy(() => import(`./audit-new/rpa-logs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/navigation-history`}
          component={lazy(() => import(`./audit-new/navigation-history`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/navigation-history-report`}
          component={lazy(() => import(`./audit-new/navigation-history-consent`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/ai-tool-usage`}
          component={lazy(() => import(`./audit-new/ai-tools`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/audit-new/reports`}
          component={lazy(() => import(`./audit-new/reports`))}
        />
        <Route path={`${APP_PREFIX_PATH}/gws`} component={lazy(() => import(`./gws`))} />
        <Route
          path={`${APP_PREFIX_PATH}/guacamole`}
          component={lazy(() => import(`./guacamole`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard-security`} />
        <Redirect
          from={`${APP_PREFIX_PATH}/redirect`}
          to={`${APP_PREFIX_PATH}/dashboard-security`}
        />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
