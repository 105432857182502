import {SET_DISABLED_BY_INHERITANCE} from '../constants/General'
const initState = {
    disabledByInheritance: false
}

const general = (state = initState, action) => {
  switch (action.type) {
    case SET_DISABLED_BY_INHERITANCE:
      return {
        ...state,
        disabledByInheritance: action.disabledByInheritance
      }
    default:
      return state
  }
}

export default general
