import fetch from 'auth/FetchInterceptor'
import { TableSorting } from 'utils/enum'
import { formatFiltersForRest } from 'utils/filter'

export const getWorkspaceAlerts = (pageSize, pageNumber, filters, sorting) => {
  let formattedFilters = formatFiltersForRest(filters)
  let sortingStr = ''
  if (sorting.sortField && sorting.sortOrder)
    sortingStr = `&sort=${sorting.sortField},${TableSorting[sorting.sortOrder]}`

  return fetch({
    url: `admin-alert?&limit=${pageSize}&page=${pageNumber}${formattedFilters}${sortingStr}`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const updateOneWorkspaceAlert = ({ id, status }) => {
  return fetch({
    url: `admin-alert/${id}`,
    method: 'patch',
    data: {
      status,
    },
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}

export const deleteOneWorkspaceAlert = (id) => {
  return fetch({
    url: `admin-alert/${id}`,
    method: 'delete',
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
export const getNewWorkspaceAlerts = () => {
  return fetch({
    url: `admin-alert?filter=status||in||New&sort=createdAt,DESC`,
    method: 'get',
  })
    .then((data) => data || [])
    .catch((err) => console.log(err))
}

export const updateBulkWorkspaceAlert = (ids) => {
  return fetch({
    url: `admin-alert/bulk/status`,
    method: 'post',
    data: {
      ids,
    },
  })
    .then((data) => data)
    .catch((err) => console.log(err))
}
